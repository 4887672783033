.header {
  @apply fixed top-0 left-0 right-0 z-50 text-white transition duration-150 ease-in-out rounded-b-2xl overflow-hidden;
}
.header__content {
  @apply flex items-center justify-between w-full h-16 px-8 xl:h-20 transition-all duration-150 ;
}
.header__collapsible__menu {
  @apply flex flex-col w-full overflow-hidden max-h-0 transition-all duration-150 rounded-b-3xl;
}
.header__content.active, .header__collapsible__menu.active {
  @apply bg-theme-primary;
}