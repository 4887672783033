
.tpg--h1 {
  @apply text-6xl md:text-7xl lg:text-8xl;
}
.tpg--h2 {
  @apply text-4xl md:text-5xl lg:text-6xl;
}
.tpg--h3 {
  @apply text-3xl md:text-4xl lg:text-5xl ;
}
.tpg--h4 {
  @apply text-2xl md:text-3xl lg:text-4xl;
}
.tpg--h5 {
  @apply text-lg md:text-xl lg:text-2xl;
}
.tpg--h6 {
  @apply text-base md:text-lg lg:text-xl;
}
.tpg--subtitle1 {
  @apply text-base;
}
.tpg--subtitle2 {
  @apply text-sm;
}
.tpg--body1 {
  @apply text-base text-gray-600;
}
.tpg--body2 {
  @apply text-sm text-gray-600;
}
.tpg--thin {
  @apply font-thin;
}
.tpg--extralight {
  @apply font-extralight;
}
.tpg--light {
  @apply font-light;
}
.tpg--normal {
  @apply font-normal;
}
.tpg--medium {
  @apply font-medium;
}
.tpg--semibold {
  @apply font-semibold;
}
.tpg--bold {
  @apply font-bold;
}
.tpg--extrabold {
  @apply font-extrabold;
}
.tpg--black {
  @apply font-black;
}
.tpg--line-height-none {
  @apply leading-none;
}
.tpg--line-height-tight {
  @apply leading-tight;
}
.tpg--line-height-snug {
  @apply leading-snug;
}
.tpg--line-height-normal {
  @apply leading-normal;
}
.tpg--line-height-relaxed {
  @apply leading-relaxed;
}
.tpg--line-height-loose {
  @apply leading-loose;
}