.btn {
  @apply flex items-center justify-center border transition duration-200 rounded-full space-x-2 font-semibold;
}

.btn--sm {
  @apply text-xs py-1.5 px-3 flex items-center justify-center; 
}
.btn--md {
  @apply text-sm py-2 px-4 flex items-center justify-center; 
}
.btn--lg {
  @apply text-base py-2.5 px-5;
}

.btn--primary, .btn--primary.btn--outline.active {
  @apply bg-theme-primary border-theme-primary text-white hover:bg-theme-primary;
}
.btn--primary.btn--outline, .btn--primary.btn--round-outline {
  @apply bg-transparent border-theme-primary text-theme-primary hover:bg-theme-primary-100 ;
}
.btn--secondary {
  @apply bg-theme-secondary border-theme-secondary text-white hover:bg-theme-secondary;
}
.btn--secondary.btn--outline, .btn--theme-secondary.btn--round-outline{
  @apply bg-transparent border-theme-secondary text-theme-secondary  hover:bg-theme-secondary-100 ;
}
.btn--tertiary {
  @apply bg-theme-tertiary border-theme-tertiary text-black hover:bg-theme-tertiary;
}
.btn--tertiary.btn--outline, .btn--tertiary.btn--round-outline{
  @apply bg-transparent border-theme-tertiary text-theme-tertiary  hover:bg-theme-tertiary-100 ;
}
.btn--orange {
  @apply bg-orange-500 border-orange-500 text-white hover:bg-orange-400;
}
.btn--orange.btn--outline, .btn--orange.btn--round-outline{
  @apply bg-transparent border-orange-500 text-orange-500  hover:bg-orange-100 ;
}

.btn--gray {
  @apply bg-gray-500 border-gray-500 text-white hover:bg-gray-500;
}
.btn--gray.btn--outline, .btn--gray.btn--round-outline {
  @apply bg-transparent border-gray-500 text-gray-500 hover:bg-gray-100 ;
}

.btn--round-outline {
  @apply rounded-full p-3;
}

.btn--disabled {
  @apply bg-gray-100 border-gray-100 text-gray-300 cursor-not-allowed ;
}

