@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }
  body {
    font-family: 'Montserrat', sans-serif;
    @apply text-theme-black ;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

@layer components {
  .link {
    @apply font-medium transition duration-150 ease-in-out;
  }
  .link:hover, .link.active {
    @apply text-theme-tertiary;
  }
  .section {
    @apply py-16 lg:py-32 responsive;
  }
  .section-non-responsive {
    @apply w-full py-16 lg:py-32;
  }
}

@layer utilities {
  .responsive {
    @apply px-8 max-w-md mx-auto md:px-0 w-full md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl;
  }
  .responsive--article {
    @apply md:max-w-2xl lg:max-w-2xl xl:max-w-2xl 2xl:max-w-2xl;
  }
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  .scrollbar--hidden::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .scrollbar--hidden {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}